'use client'

import { useState } from 'react';
import { Header, Form, FieldCells, FieldCell, Field, FieldLabel, Button } from '@ginieapp/ginie-ui';
import styles from './hrx.module.scss'
import Image from "next/image";
import ApiUtils from '@/utils/ApiUtils';
import { useRouter } from 'next/navigation';

export default function LoginScreen(props) {

  const router = useRouter()

  const [form,setForm] = useState({})

  const onChange = (e) => {
    let data = Object.assign({}, form)
    data[e.target.name] = e.target.value
    setForm(data)
  }

  const login = (e) => {
    if (e) e.preventDefault()
    ApiUtils.postRequest("/t2/v1/users/login", { username: form.username, password: form.password })
    .then(data => {
      if (props.onLogin) {
        props.onLogin(e)
      } else {
        router.push("/dealer/automation")
      }
    })
  }

  return <div className='page'>
    <Header className={styles.header}>
      <div className={styles.header_logo}>
        <img src={process.env.NEXT_PUBLIC_CDN + "/images/highroadx-logo.png"}></img>
      </div>
      <span></span>
      <div className={styles.header_dlogo}>
        <img src={process.env.NEXT_PUBLIC_CDN + "/images/dealerx-logo.svg"}></img>
      </div>
    </Header>
    <main className='flex-centered'>
      <Image src={process.env.NEXT_PUBLIC_CDN + "/images/home-bg.svg"} alt={'Base Screen Background'} fill={'object-fit'} style={{ maxWidth: "100%", objectFit: "cover" }} priority={true} sizes={'1024x1024'}></Image>
      <div className={styles.form}>
        <Form id="login_form" name="Login Form" onSubmit={e => login(e)}>
          <FieldCells>
            <FieldCell>
              {/* <FieldLabel for={'username'} className={styles.label}>Username</FieldLabel> */}
              <Field type="text" label="Username" name="username" value={form.username || ''} placeholder="Username" theme="trn-line" onChange={e => onChange(e)}></Field>
            </FieldCell>
            <FieldCell>
              {/* <FieldLabel for={'password'} className={styles.label}>Password</FieldLabel> */}
              <Field type="password" label="Password" name="password" value={form.password || ''} placeholder="Password" theme="trn-line" onChange={e => onChange(e)}></Field>
            </FieldCell>
            <FieldCell>
              <Button theme={['primary']}>Login</Button>
            </FieldCell>
          </FieldCells>
        </Form>
      </div>
    </main>
  </div>


}