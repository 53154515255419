import { emitEvent } from '@ginieapp/ginie-ui';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';

export const USER_AGENT = "HRX/v1.0"

export const SITE_COOKIE_NAME = "_hrdx_vid"
export const ACCESS_TOKEN_COOKIE_NAME = "_hrdx_token"

export default class ApiUtils {

  static events = []
  static _partnerKey = null
  static _currentUser = null
  static _accessToken = null

  static getFullUrl = (urlStr) => {
    if (urlStr.indexOf("http") == 0) {
      return urlStr;
    }
    return urlStr
  }
  
  static defaultHeaders = () => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'User-Agent': USER_AGENT,
    }
    if (this._partnerKey) {
      headers['X-API-KEY'] = this._partnerKey
    }
    if (this.authToken()) {
      headers['Authorization'] = "Bearer " + this.authToken()
    }
    return headers
  }
  
  static clearToken = () => {
    this._accessToken = null
    deleteCookie(ACCESS_TOKEN_COOKIE_NAME, { path: "/" })
    deleteCookie(SITE_COOKIE_NAME, { path: "/" })
  }
  
  static authToken = () => {
    if (this._accessToken) return this._accessToken
    return getCookie(ACCESS_TOKEN_COOKIE_NAME)
  }
  
  static readAuthHeader = (response) => {
    if (response.headers.get('Authorization')) {
      this._accessToken = response.headers.get('Authorization').split(" ")[1]
      setCookie(ACCESS_TOKEN_COOKIE_NAME, this._accessToken)
    }
  }

  static checkAuth = () => {
    const url = this.getFullUrl("/t2/v1/users/me")
    return fetch(url, {
      method: 'GET',
      headers: this.defaultHeaders()
    }).then(response => {
      if (response.ok) {
        this.readAuthHeader(response)
        return response.json()
      } else {
        this.readAuthHeader(response)
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        return error
      }
    }).then(data => {
      if (data.user) {
        this._currentUser = data.user
        emitEvent("log_user_id", { id: data.user.uqid || data.user.user_id || data.user.id, email: data.user.email })
      }
      return data
    })
  }

  static getRequest = (urlStr, params) => {

    let url = this.getFullUrl(urlStr)
    if (urlStr.indexOf("?") < 0) url += "?"
    if (params) {
      Object.keys(params).forEach(function (key) {
        if (params[key]) {
          url += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
        }
      });
    }

    if (process.env.NODE_ENV == "developement") console.log("GET " + url)

    return fetch(url, {
      method: 'GET',
      headers: this.defaultHeaders()
    }).then(response => {
      if (response.ok) {
        this.readAuthHeader(response)
        return response.json()
      } else {
        this.readAuthHeader(response)
        let error = { http: { status: response.status, url: url } }
        this.logError("HTTP (" + response.status + ") GET ERROR - " + url, error, response)
        return { error: error }
      }
    })
  }

  static postRequest = (urlStr, data, method) => {
    let url = this.getFullUrl(urlStr)
    var jsonBody = data
    var contentType = 'application/json'

    if (process.env.NODE_ENV == "developement") console.log("POST " + url)
    
    let headers = this.defaultHeaders()

    return fetch(url, {
      method: method || 'POST',
      headers: headers,
      body: JSON.stringify(jsonBody)
    })
      .then(response => {
        if (response.ok) {
          this.readAuthHeader(response)
          return response.json()
        } else {
          this.readAuthHeader(response)
          let error = { http: { status: response.status, url: url, payload: jsonBody, response: response, stacktrace: new Error().stack } }
          this.logError("HTTP (" + response.status + ") - POST ERROR - " + url, error)
          return { error: error }
        }
      })
  }

  static postMultipart = (urlStr, formData) => {
    let url = this.getFullUrl(urlStr)
    
    let headers = this.defaultHeaders()
    delete headers['Content-Type']

    if (process.env.NODE_ENV == "developement") console.log('POST ' + url)
    return fetch(url, {
      method: 'POST',
      headers: headers,
      body: formData
    })
      .then(response => {
        if (response.ok) {
          this.readAuthHeader(response)
          return response.json()
        } else {
          this.readAuthHeader(response)
          let error = { http: { status: response.status, url: url, payload: formData } }
          this.logError("HTTP (" + response.status + ") POST MULTIPART ERROR - " + url, error)
          return { error: error }
        }
      })
  }
  
  static logError = async (message, error, response) => {
    if (error && error.http && response) error.http.body = await response.text()
    if (process.env.NODE_ENV == 'development') {
      if (message) console.error(message, error)
      if (!message) console.error(error)
    }
    emitEvent("log_error", { message: message, data: error })
  } 

  static logEvent = (event) => {
    this.events.push(event)
    setTimeout(() => {
      if (ApiUtils.events.length > 0) {
        const array = [...ApiUtils.events]
        ApiUtils.events = []
        ApiUtils.postRequest("/api/visitor/track", { payload: { events: array } })
      }
    }, 5000)
  }

}
